import { PreSpan } from '../../ui-primitives/pre-span'

const defaultQuery = `
select * where {
    VALUES (?user ?user_icon ?user_URL ?user_iconSize ?name ?msg ?msg_URI ?msg_URL) {
        ('Sander' 'fa-face-rolling-eyes' 'https://bim-connected.slite.com/app/docs/uOj8o7XyCZx448' '2rem' 'Sander' 'Hoi' 'https://google.com' undef)
        ('Norent' 'fa-person-falling-burst' 'https://bim-connected.slite.com/app/docs/-r18oHM4poM-WX' '2rem' 'Norent' 'Hiiii' undef 'https://bing.com')
    }
}
`

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    description:"extra documentation",
    defaultQuery,
    label: [
      'Query-variable-names structured as "?{identifier}_{modifier}" modify',
      'the display query-variable-results of "?{identifier}",',
      'see help for more info',
    ].join(' '),
    helpComponent: QueryHelp
  },
  {
    name: 'hideColumnHeaders',
    type: 'boolean',
    label: 'hide column headers',
  },
  {
    name: 'showFilters',
    type: 'boolean',
    label: 'enable filters',
  },
  {
    name: 'idToFilterTextOf',
    type: 'text',
    label: 'identifier to filter text of',
  },
  {
    name: 'makeRowsSelectable',
    type: 'boolean',
    label: 'make rows selectable',
  },
  {
    name: 'enableMockActions',
    type: 'boolean',
    label: 'enableMockActions',
  },
] as const

function QueryHelp() {
  return (
    <div>
      <p className="mb-3">
        The results of this query are displayed in the table in the same order
        as they are returned from the triplestore.
      </p>
      <p className="mb-3">
        It is possible to modify a cell by selecting sparql-variables with the
        following name-shape <PreSpan>{'?{identifier}_{modifier}'}</PreSpan>.
        Rows have an empty identifier, e.g. <PreSpan>{'?_{modifier}'}</PreSpan>.
      </p>
      <p>
         The following <PreSpan>{'modifier'}</PreSpan>s have been:
      </p>
      <ul className="list-disc list-inside">
        <li>No modifier has been implemented yet. So modifiers just get ignored for now.</li>
      </ul>
    </div>
  )
}