import { useEffect, useState } from 'react'
import { resolveSparqlTemplate } from '../helpers/resolve-sparql-template'
import { OpaqueSparqlResults } from '../helpers/sparqlResultHelpers'
import { SparqlTemplate } from '../helpers/SparqlManager'

type PublishFn = (topic: string, value: unknown) => void

export function SparqlTriggerExecutor(props: {
  endpoint: string
  sparqlTemplate: string
  templateId: string
  pubVars: Record<string, any>
  publish: PublishFn
  signalIsFetching?: (isFetching: boolean) => any
}) {
  const [query, setQuery] = useState<string>()
  const sparqlManager = useSparqlTemplate(props.templateId, props.endpoint);

  useEffect(() => {
    try {
      const resolvedQuery = resolveSparqlTemplate(props.sparqlTemplate, props.pubVars)
      setQuery(resolvedQuery)
    } catch(e) { console.error(e) }
  }, [props.endpoint, props.publish, props.pubVars, props.sparqlTemplate, props.templateId])

  useEffect(() => {
    if (!query || !sparqlManager) return
    (async () => {
      try {
        props.signalIsFetching?.(true)
        const response = await sparqlManager.fetch(query)
        props.signalIsFetching?.(false)
        if (!response.isLatest()) return

        publishResults(response.result, props.publish)
      } catch(e) { console.error(e) }
    })()
  }, [sparqlManager, query]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => props.signalIsFetching?.(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

function useSparqlTemplate(templateId: string, endpoint: string) {
  const [sparqlManager, setSparqlManager] = useState<SparqlTemplate>()
  useEffect(() => {
    setSparqlManager(new SparqlTemplate(endpoint, templateId))
  }, [templateId, endpoint])

  return sparqlManager
}

function publishResults(results: OpaqueSparqlResults, publish: PublishFn) {
  const rows = results.results.bindings
  if (rows.length !== 1) throw new Error('got more than one result')

  for (const topic of results.head.vars) publish(topic, rows[0][topic].value)
}
