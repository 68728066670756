import { lazy } from 'react';
import WidgetCategory from '../../WidgetCategory';
import PublishUtils from '../../../helpers/PublishUtils';



export default class IFCJSViewerV3Descriptor {

  static getDescription() {
    return {
      name: 'IFCJSViewerV3',
      component:  lazy(() =>  import('./IFCJSViewerV3')), 
      label: WidgetCategory.experimental+' IFC Viewer V3 (BIM-Legal development) ',
      category:WidgetCategory.experimental,
      variables: [
        {
          name: 'files prefix',
          type: 'text',
          label: 'prefix for all ifc files (e.g. http://wistor.nl/servlets/cgi/download/repo/) ',
        },
        {
          name: 'ifcfiles',
          type: 'array',
          label: 'comma separated url(s) to IFC files to display. parameters can be used here referring to (wistor) download locations using the wistor download servlet',
        },
        {
          name: 'ifctiles',
          type: 'array',
          label: 'comma separated url(s) to IFC tiles to display. parameters can be used here referring to (wistor) download locations using the wistor download servlet',
        },
        {
          name: 'Visualisation mode',
          type: 'select',
          label: '2d of 3d modus',
          options: [
            {
              label: '3D',
              value: '3D',
            },
            {
              label: '2D',
              value: '2D',
            }
          ]},
        {
          name: 'Object visualisation mode',
          type: 'select',
          label: 'options to visualise IFC data icw Sparql results',
          options: [
            {
              label: 'show only sparql results ',
              value: 'sparqlonly',
            },
            {
              label: 'show all transparent (sparql results in color) ',
              value: 'transparent',
            },
            {
              label: 'normal (no sparql necessary)',
              value: 'normal',
            }
            
          ]},
      
      
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter ?guid (necessary to link?) ?uri (handy for publishing) ?hidden (false/true) ?alpha ?color (create a color based upon this value) ?label (popup label)  ',
          defaultQuery:""
        },
        {
          name: 'publishVariable',
          type: 'text',
          label: 'sparql parameter name for publishing select event result. Arguably we need GUIDS to link data to the viewer. Perhaps GUID based interaction (publish guids instead of URIs is handier)',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label: 'Published variabele on which to listen for selection.',
        },
        {
          name: 'multipleselect',
          type: 'boolean',
          label: 'allow multiple selections. parameters are filled as comma separated values',
        },
       
        PublishUtils.getMultipleValueFormatOptions(),
        PublishUtils.getResetValueDef(),
        
        /*,
    {
      name: 'clearParameter',
      type: 'text',
      label: 'clears widget when parameter changes'
    }*/
      ],
    }
  }
}

  