import axios from 'axios'
import LoginHelper from './LoginHelper'


class Command {

  static getRepoUrl()
  {
    return window.top.repoURL;
  }

  static test()
  {
    Command.sendCommand2({commando:"stroefheidcurve",uri:"http://www.rws.nl/VenR/2023/nwb/wegvakken#RWRW016B1HRRv36p1t36p2"});
  }

  
  static sendCommand(component, command, options, rv,failure) {
    if (options == null) options = {}

   
    //let repoURL=window.cgi+"sparql";
    
    let repoURL=null;
    try {     repoURL=component.props.repoURL; } catch(e){}
   
    if (repoURL != null && repoURL.includes('sparql')) {
      let commandUrl = repoURL.replace('sparql', 'command')
      const formData = new FormData()
      formData.append('commando', command)
      options.commando = command
      if (options != null) {
        for (let key in options) {
          formData.append(key, options[key])
        }
      }
      axios.defaults.withCredentials = true
      axios.defaults.credentials = 'include'

     
        axios.post(commandUrl, options, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json',
            },
          })
          .then(function (arg) {
            console.log('SUCCESS command', arg)
            if (rv != null) {
              rv.call(this, arg)
            }
          }).catch (function(e) {
       // console.log('command error ', e);
        if (failure!=null)
        {
          failure.call(this);
        }
        else
        {
        alert('command error');
        }
      })
    }
  }

  static sendCommand2(options,success,failure) 
  {
     //for iframe calls.
     //let repoURL=window.cgi+"sparql";
     let repoURL=Command.getRepoUrl();

    if (repoURL != null && repoURL.includes('sparql')) {
      let commandUrl = repoURL.replace('sparql', 'command')
      const formData = new FormData()
      if (options != null) {
        for (let key in options) {
          formData.append(key, options[key])
        }
      }
      axios.defaults.withCredentials = true
      axios.defaults.credentials = 'include'

      try {
        axios
          .post(commandUrl, options, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json',
            },
          })
          .then(function (arg) {
         
            if (arg!=null)
            {
              console.log("\n\nRULE results");
               console.log(arg.data);
              if (arg.data!=null)
              {
               
                 try {
                 // console.log('SUCCESS 2 command',arg.data);
                    if ((arg.data.success!=null)  && (arg.data.success!=false) )
                    {
                      if (success!=null){success.call(this,arg);}
                      return;
                    }

                 }
                 catch(e)
                 {
                  console.log(e);
                 }
              }
              try 
              {
                    console.log(arg.data.errors);
              }
              catch(ex)
              {}
            }
            if (failure!=null){failure.call(this);}
          
           
          })
      } catch (e) {
        console.log('command error', e)
        if (failure!=null){failure.call(this,e);}
      }
    }
  }

  static ruleRule(uri,parameters,success,failure)
   {
    var json = {
      commando: 'VRCommands',
      command2: 'runSparqlRule',
      command3: uri,
      parameters:parameters
    }
    Command.sendCommand2(json,success,failure)
  }




  


  static runRulesWithTag(tag,parameters,success,failure) {
   // console.log('run rules met specifieke tag ' ,tag,parameters)
    var json = {
      commando: 'VRCommands',
      command2: 'runSparqlRulesWithTag',
      command3: tag,
      parameters:parameters,
      //test:"Hans was here"
    }

    Command.sendCommand2(json,success,failure)
  }

  static deleteServerCache(graph)
  {
    var json = {
      commando: 'refreshCache',
      graph: graph,
    }
    Command.sendCommand2(json)

  }

  static logoff() {
    let repoURL=window.cgi+"sparql";
    LoginHelper.logoff(repoURL);
  }

}

window.top.command = Command

export default Command
